"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  Outlet,
  matchPath,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { useRoute } from "@memed/react-router-mapping";
import {
  useFlag,
  useFlagsStatus,
  useUnleashClient
} from "@unleash/proxy-client-react";
import { isDatadogBot } from "~/utils/helpers";
import { isExam, isVaccine, isWhiteLabel } from "~/utils/validators";
import { InitialLoad } from "~/views/partials/Loader";
import { setSessionItem, getSessionItem } from "~/utils/storage";
import usePrescriptionReducer from "./reducer";
import { prescriptionApi } from "~/services/prescriptionApi";
import { assistantApi } from "~/services/assistantApi";
import { examsApi } from "~/services/examsApi";
import { gollumApi } from "~/services/gollumApi";
import { xRay } from "~/utils/tracking";
import { useAddressContext } from "../AddressContext";
const PrescriptionContext = createContext(
  {}
);
export const PrescriptionProvider = () => {
  const [
    {
      prescription,
      patient,
      isInitialLoading,
      hasError,
      prescriptionError
    },
    dispatch
  ] = usePrescriptionReducer();
  const { token } = useParams();
  const [params] = useSearchParams();
  const [turnstileToken, setTurnstileToken] = useState("");
  const [labsAvailability, setLabsAvailability] = useState([]);
  const [exclusiveLabs, setExclusiveLabs] = useState([]);
  const [farmeAvailability, setFarmeAvailability] = useState([]);
  const [unasignedPrescriptionBar, setUnasignedPrescriptionBar] = useState(true);
  const navigate = useNavigate();
  const { route } = useRoute();
  const { flagsReady } = useFlagsStatus();
  const unleashClient = useUnleashClient();
  const drugsPartnerBlocked = useFlag("DrugsPartnerBlocked");
  const drugsShowExamsExclusiveFlow = useFlag("DrugsShowExamsExclusiveFlow");
  const { address } = useAddressContext();
  const isPreviewMode = useMemo(() => {
    const isPreview = !!params.get("preview_mode") || getSessionItem("preview_mode");
    if (isPreview) {
      setSessionItem("preview_mode", "true");
    }
    return isPreview;
  }, [params]);
  const items = useMemo(() => {
    if (prescription.items) {
      return Object.keys(prescription.items).map((key) => prescription.items[key].data).flat();
    }
    return [];
  }, [prescription.items]);
  const observationCategories = useMemo(
    () => ["cid_exames", "observacao_exames"],
    []
  );
  const prescribedItems = useMemo(
    () => items.filter(
      (item) => !observationCategories.includes(item.custom_category)
    ),
    [items, observationCategories]
  );
  const prescribedItemsWithoutEan = useMemo(
    () => prescribedItems.filter((item) => !item.eans.length),
    [prescribedItems]
  );
  const prescribedObservations = useMemo(
    () => items.filter((item) => observationCategories.includes(item.custom_category)),
    [items, observationCategories]
  );
  const hasControlledItem = useMemo(
    () => items.some((item) => ["C1", "C5", "ATB"].includes(item.recipe.code)),
    [items]
  );
  const hasAtb = useMemo(
    () => items.find((item) => item.recipe.code === "ATB"),
    [items]
  );
  const isPartnerBlocked = useMemo(
    () => flagsReady ? drugsPartnerBlocked : false,
    [flagsReady, drugsPartnerBlocked]
  );
  const isRejectedTerms = useMemo(() => {
    if (patient.itens && patient.itens.length) {
      const { status } = patient.itens[0];
      return status === "REJECTED";
    }
    return false;
  }, [patient.itens]);
  const isNotDefined = useMemo(() => {
    if (patient.itens && patient.itens.length) {
      const { status } = patient.itens[0];
      return status === "NOT_DEFINED";
    }
    return false;
  }, [patient.itens]);
  const eans = useMemo(() => {
    const eans2 = [...new Set(items.map((item) => item.eans).flat())];
    xRay.persistData("eans", eans2.toString());
    return eans2;
  }, [items]);
  const hasEan = useMemo(() => eans.length > 0, [eans]);
  const trackPrescriptionPageViewLabsAvailability = useCallback((labsAvailability2) => {
    var _a, _b, _c;
    const hasLabsAvailable = labsAvailability2.some((item) => item.labs.length > 0);
    if (hasLabsAvailable && address.city && address.state) {
      const labSet = /* @__PURE__ */ new Set();
      labsAvailability2.forEach((lab) => lab.labs.forEach((l) => labSet.add(l)));
      const uniqueLabsArray = Array.from(labSet);
      xRay.sendEvent({
        name: "Prescription Pageview Labs Elegible",
        screen: "assistant_home",
        properties: {
          has_drugs: !!((_a = prescription.items) == null ? void 0 : _a.industrialized.data.length) || !!((_b = prescription.items) == null ? void 0 : _b.manipulated.data.length),
          has_exams: !!((_c = prescription.items) == null ? void 0 : _c.exams.data.length),
          first_open: prescription.isFirstOpen,
          has_expired: prescription.has_expired || false,
          signed: prescription.isSigned,
          labs_elegible: uniqueLabsArray,
          items_json: prescription.items
        }
      });
    }
  }, [address, prescription]);
  const getLabsAvailability = useCallback((prescription2, address2) => __async(void 0, null, function* () {
    if (prescription2.items && address2.city && address2.state) {
      const mapped = [];
      const differentTypes = Object.keys(prescription2.items);
      differentTypes.forEach((type) => {
        prescription2.items[type].data.forEach((item) => {
          if (isExam(item)) {
            mapped.push({
              id: item.id,
              type: "exam",
              codes: [item.tuss_code]
            });
          }
          if (isVaccine(item)) {
            mapped.push({
              id: item.id,
              type: "vaccine",
              codes: item.eans
            });
          }
        });
      });
      if (mapped.length > 0 && address2.city && address2.state) {
        const response = yield examsApi.getLabsAvailability({
          city: address2.city,
          state: address2.state,
          items: mapped,
          token: prescription2.token
        });
        setLabsAvailability(response.data);
        trackPrescriptionPageViewLabsAvailability(response.data);
      }
    }
  }), [setLabsAvailability, trackPrescriptionPageViewLabsAvailability]);
  const getFarmeAvailability = useCallback(() => __async(void 0, null, function* () {
    try {
      const { data: avaiableItems } = yield gollumApi.getFarmeAvailability(address.postalCode, eans.join(","));
      setFarmeAvailability(avaiableItems);
    } catch (error) {
      console.log(error);
    }
  }), [eans, address.postalCode]);
  const getExclusiveLabs = useCallback(() => __async(void 0, null, function* () {
    try {
      const labs = yield examsApi.getExclusiveLabs({ partnerId: prescription.partner.id, address: { city: address.city, state: address.state } });
      setExclusiveLabs(labs.data.partnerLaboratories);
    } catch (error) {
      console.error("Erro ao consultar os parceiros exclusivos!", error);
    }
  }), [prescription, address]);
  const getData = useCallback(() => __async(void 0, null, function* () {
    try {
      const {
        data: { data: prescriptionData }
      } = yield prescriptionApi.getPrescription({ token });
      if (prescriptionData) {
        const { prescription: prescription2 } = prescriptionData.attributes;
        const {
          id: prescriptionId,
          token: prescriptionToken,
          sent_via: prescriptionTokenSource,
          items: items2,
          isFirstOpen,
          has_expired,
          isFirstOpenAt,
          created_at,
          patient: patient2
        } = prescription2;
        xRay.persistData(
          "items_json",
          items2.industrialized.data.map((item) => ({
            id: item.id,
            item_name: item.name,
            item_type: item.related_type,
            ingredient_name: item.description,
            industry_lab: item.manufacturer,
            is_sponsored: false,
            prescription_type: item.recipe.name,
            lme: item.high_cost,
            item_category: item.ownership
          }))
        );
        yield xRay.init({
          prescriptionId: Number(prescriptionId),
          prescriptionToken,
          prescriptionTokenSource
        });
        yield xRay.identify(patient2.id);
        if (isFirstOpenAt) {
          const prescriptionCreatedAt = new Date(created_at);
          const prescriptionOpenAt = new Date(isFirstOpenAt);
          const timeDifference = prescriptionOpenAt.getTime() - prescriptionCreatedAt.getTime();
          const timeDifferenceInDays = Math.floor(
            timeDifference / (1e3 * 60 * 60 * 24)
          );
          xRay.persistData("prescription_delta_days", timeDifferenceInDays);
        }
        const isPrescriptionRoute = matchPath(
          { path: "/p/:token/receita" },
          window.location.pathname
        );
        xRay.sendEvent({
          name: "Prescription Open",
          screen: isPrescriptionRoute ? "assistant_home" : "direct_sale_drugs_listing",
          properties: {
            first_open: isFirstOpen,
            has_drugs: !!items2.industrialized.data.length || !!items2.manipulated.data.length,
            has_exams: !!items2.exams.data.length,
            has_expired: has_expired || false
          }
        });
        const { data: dataPatient } = yield assistantApi.getPatientById(
          patient2.id
        );
        dispatch({
          type: "COMPLETE_PRESCRIPTION",
          payload: {
            prescription: prescription2,
            patient: dataPatient
          }
        });
      }
    } catch (error) {
      const { response } = error;
      console.error("Erro ao carregar a receita: ", error);
      dispatch({
        type: "ERROR_PRESCRIPTION",
        payload: {
          prescriptionError: {
            exists: true,
            status: (response == null ? void 0 : response.status) || 500
          }
        }
      });
    }
  }), [token, dispatch]);
  const updateTerms = useCallback(
    (payload, code = "") => __async(void 0, null, function* () {
      try {
        const { patient: patient2 } = prescription;
        if (token) {
          const { data } = yield assistantApi.updatePatient({
            id: patient2.id,
            token,
            code,
            itens: payload
          });
          dispatch({
            type: "UPDATE_PRIVACY",
            payload: {
              patient: data
            }
          });
          return data;
        }
      } catch (e) {
        dispatch({ type: "ERROR_PRIVACY" });
      }
      return {};
    }),
    [dispatch, prescription, token]
  );
  const unlockPrescription = useCallback(
    (code = "") => __async(void 0, null, function* () {
      let turnstile = turnstileToken || getSessionItem("turnstile_token");
      if (turnstile === "") {
        return {};
      }
      try {
        turnstile = !isDatadogBot ? turnstile : null;
        const { data } = yield prescriptionApi.getPrescription({
          token,
          turnstile,
          digits: code
        });
        if (data.data) {
          const {
            digits,
            isSigned,
            document,
            doctor,
            patient: patient2
          } = data.data.attributes.prescription;
          dispatch({
            type: "UPDATE_PRESCRIPTION",
            payload: {
              prescription: {
                digits,
                isSigned,
                document,
                doctor,
                patient: patient2
              }
            }
          });
        }
        return data;
      } catch (error) {
        console.error("N\xE3o foi poss\xEDvel realizar o download", error);
        dispatch({ type: "ERROR_PRIVACY" });
      }
      return {};
    }),
    [token, dispatch, turnstileToken]
  );
  const clearErrors = useCallback(() => {
    dispatch({ type: "CLEAR_ERRORS" });
  }, [dispatch]);
  const updateUnleash = useCallback(() => {
    try {
      if (!isInitialLoading && !flagsReady) {
        if (prescription.partner) {
          unleashClient.setContextField("partnerId", prescription.partner.id);
        }
        if (eans.length) {
          unleashClient.setContextField("eans", eans.join(","));
        }
        unleashClient.start();
      }
    } catch (error) {
      console.error("Erro ao consultar o unleash", error);
    }
  }, [eans, flagsReady, isInitialLoading, prescription.partner, unleashClient]);
  useEffect(() => {
    if (prescriptionError.exists) {
      if ([401, 403, 404].includes(prescriptionError.status)) {
        navigate(route("prescription-deleted"));
        return;
      }
      navigate(route("prescription-not-found"));
    }
  }, [prescriptionError, navigate, route]);
  useEffect(() => {
    getData();
  }, [getData]);
  const hasExams = items.some((item) => isExam(item));
  const hasVaccine = items.some((item) => isVaccine(item));
  const hasStateAndCity = address.city && address.state;
  const isExamsExclusiveFlow = useMemo(
    () => {
      var _a, _b;
      return ((_b = (_a = prescription.partner) == null ? void 0 : _a.exams_flow) == null ? void 0 : _b.id) === 1 && hasExams && drugsShowExamsExclusiveFlow;
    },
    [prescription.partner, hasExams, drugsShowExamsExclusiveFlow]
  );
  useEffect(() => {
    var _a;
    if (((_a = prescription == null ? void 0 : prescription.partner) == null ? void 0 : _a.id) && hasStateAndCity && hasExams) {
      getExclusiveLabs();
    }
    if (prescription.items && hasStateAndCity && (hasExams || hasVaccine)) {
      getLabsAvailability(prescription, address);
    }
  }, [address, hasStateAndCity, prescription, getLabsAvailability, hasExams, hasVaccine, getExclusiveLabs]);
  const canTriggerFarmeAvailability = items.some((item) => isWhiteLabel(item));
  useEffect(() => {
    if (address.city && canTriggerFarmeAvailability) {
      getFarmeAvailability();
    }
  }, [address.city, getFarmeAvailability, canTriggerFarmeAvailability]);
  useEffect(() => {
    updateUnleash();
  }, [updateUnleash]);
  const value = useMemo(
    () => ({
      prescription,
      exclusiveLabs,
      patient,
      isInitialLoading,
      isRejectedTerms,
      isNotDefined,
      isPartnerBlocked,
      isPreviewMode,
      hasError,
      hasEan,
      hasControlledItem,
      hasAtb,
      eans,
      prescribedItems,
      prescribedObservations,
      prescribedItemsWithoutEan,
      updateTerms,
      unlockPrescription,
      clearErrors,
      turnstileToken,
      setTurnstileToken,
      labsAvailability,
      isExamsExclusiveFlow,
      farmeAvailability,
      unasignedPrescriptionBar,
      setUnasignedPrescriptionBar
    }),
    [
      labsAvailability,
      exclusiveLabs,
      prescription,
      patient,
      isInitialLoading,
      isRejectedTerms,
      isNotDefined,
      isPartnerBlocked,
      isPreviewMode,
      hasError,
      hasEan,
      hasControlledItem,
      hasAtb,
      eans,
      prescribedItems,
      prescribedObservations,
      prescribedItemsWithoutEan,
      updateTerms,
      unlockPrescription,
      clearErrors,
      turnstileToken,
      setTurnstileToken,
      isExamsExclusiveFlow,
      farmeAvailability,
      unasignedPrescriptionBar,
      setUnasignedPrescriptionBar
    ]
  );
  return /* @__PURE__ */ jsx(PrescriptionContext.Provider, { value, children: !isInitialLoading && flagsReady ? /* @__PURE__ */ jsx(Outlet, {}) : /* @__PURE__ */ jsx(InitialLoad, {}) });
};
export const usePrescriptionContext = () => useContext(PrescriptionContext);
