"use strict";
import { Service } from "../service";
class GollumApi extends Service {
  constructor() {
    super(process.env.API_GOLLUM);
  }
  /**
   * Verifica se um usuario é elegível ao programa de assinaturas da Farme
   * @param {IGetFarmeAvailabilityResponse} cep, eans
   * @returns {Promise}
   */
  getFarmeAvailability(cep, eans) {
    return this.instance.get(`eligibility/check?cep=${cep}&eans=${eans}`);
  }
}
export const gollumApi = new GollumApi();
